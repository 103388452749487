
@import "colors";
@import "fonts";
@import "bootstrap/scss/bootstrap.scss";

html, body {
  font-family: 'Titillium', sans-serif;
  background-color: $secondary;
  color: $gray;
}

#top {
  height: 32px;
  background-color: $primary;
}

#main {
  background-color: $white;
  h1 {
    text-transform: uppercase;
    color: $secondary;
    font-weight: 700;
  }
  h2 {
    color: $gray;
    font-weight: 700;
  }
  button.btn {
    &.btn-primary {
      color: $secondary;
      font-weight: 700;
      .link-external {
        height: 15px;
        width: 15px;
      }
    }
  }
}

#foot {
  border-top: 5px solid $primary;
  min-height: 32px;
  background-color: $secondary;
  color: $primary;

  nav {
    ul.nav {
      li.nav-item {
        a.nav-link {
          .link-external {
            height: 10px;
            width: 10px;
          }
        }
      }
    }
  }

}