
@font-face {
  font-family: 'Titillium';
  src: url("../webfonts/TitilliumWeb-Regular.ttf") format('truetype');
}

@font-face {
  font-family: 'Titillium';
  src: url("../webfonts/TitilliumWeb-Bold.ttf") format('truetype');
  font-weight: 700;
}